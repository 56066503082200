<template>
  <div
    class="bg-transparent min-h-screen px-4 py-16 sm:px-6 sm:py-24 xl:grid xl:place-items-top lg:px-8"
  >
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold encom_pink_text sm:text-5xl">
          {{ status }}
        </p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1
              class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
            >
              Page not found
            </h1>
            <p class="mt-1 text-base text-gray-500">
              Sorry, something wrong happened. Please contact your line manager.
            </p>
            <p class="mt-1 text-base text-gray-500">
              Reason : <b>{{ this.msg }}</b>
            </p>
          </div>
          <div
            class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"
          >
            <a
              href="/"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white encom_table_header encom_ring_color"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: ["msg", "status"],
};
</script>

<style></style>
