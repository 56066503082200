<template>
  <div class="flex flex-col invoice_table">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div
          v-if="this.documentsCustomer.length > 0"
          class="overflow-hidden border-b border-gray-200 shadow"
        >
          <!--  <div class="pb-5">
            <TablePagination
              :documentHeaderName="$t('documentTable.allDocuments')"
              :tableData="this.documentsOrganisation"
            />
          </div> -->

          <TablePagination
            :documentHeaderName="$t('documentTable.yourDocuments')"
            :tableData="this.documentsCustomer"
            @activeBanner="(activeBanner = true), (key = !key)"
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
        <div v-else-if="this.error.status" class="overflow-hidden">
          <Error :status="this.error.status" :msg="this.errorMsg" />
        </div>
        <div v-else class="flex justify-center pt-20">
          <div class="text-center">
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vector-effect="non-scaling-stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("warning.noDataFoundDocumentTitle") }}
            </h3>
            <p class="mt-1 text-base text-gray-500">
              {{ $t("warning.noDataFoundDocumentDescription") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import Error from "../components/Error.vue";

import axios from "axios";

import TablePagination from "../components/TablePagination.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const account = localStorage.getItem("account");

export default {
  name: "Documents",
  components: {
    Error,
    TablePagination,
    Loading,
  },
  data() {
    return {
      error: {},
      errorMsg: "",
      documentsCustomer: [],
      documentsOrganisation: [],
      account,
      isLoading: true,
      fullPage: false,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    async getDocuments() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/documents?customerAccount=${
            this.account
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        this.documentsCustomer = res.data.customer;
        this.documentsOrganisation = res.data.organisation;
      } catch (error) {
        this.errorHandling(error);
        if (error.response.status) {
          this.error = error.response;
          this.errorMsg = error.response.data.message;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<style></style>
